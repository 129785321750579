// @ts-strict-ignore
import { getCookieSettings } from './cookies'

type Window = globalThis.Window &
  typeof globalThis & {
    pintrk: any
  }

type CheckoutItem = {
  product_id: string
  product_price: number
  product_quantity: number
}

type Checkout = {
  value: number
  items: CheckoutItem[]
}

type AddToCart = {
  product_id: string
  product_quantity: number
}

type Lead = {
  lead_type: 'product-question' | 'product-appointment'
  product_id: string
}

const cookieSettings = getCookieSettings()
const trackingPermission =
  cookieSettings.includes('analytical') && cookieSettings.includes('personal')
const typedWindow = typeof window !== 'undefined' ? (window as Window) : null

export default {
  pageVisit() {
    if (typedWindow.pintrk && trackingPermission) {
      typedWindow.pintrk('track', 'pagevisit')
    }
  },

  checkout({ value, items }: Checkout) {
    if (typedWindow.pintrk && trackingPermission) {
      const obj = {
        value,
        line_items: items.map(({ product_id, product_price, product_quantity }) => ({
          product_id,
          product_price,
          product_quantity,
        })),
      }
      typedWindow.pintrk('track', 'checkout', obj)
    }
  },

  addToCart({ product_quantity, product_id }: AddToCart) {
    if (typedWindow.pintrk && trackingPermission) {
      const obj = {
        line_items: [
          {
            product_id,
            product_quantity,
          },
        ],
      }
      typedWindow.pintrk('track', 'addtocart', obj)
    }
  },

  lead({ lead_type, product_id }: Lead) {
    if (typedWindow.pintrk && trackingPermission) {
      const obj = {
        lead_type,
        line_items: [
          {
            product_id,
          },
        ],
      }
      typedWindow.pintrk('track', 'lead', obj)
    }
  },
}
