import * as styles from './BrandsCard.styles'
import { WishListButton } from '@components/gassan-ui'
import { linkStyle } from '@components/gassan-ui/Typography/linariaStyles'
import { cx } from '@linaria/core'
import { FC } from 'react'

type BrandsCardProps = {
  imageSource: string | null
  description: string
  name: string
  brandId: number | null
  isOnWishlist: boolean
  onClickWishlist: () => void
}

export const BrandsCard: FC<BrandsCardProps> = ({
  description,
  imageSource,
  name,
  isOnWishlist,
  brandId,
  onClickWishlist,
}) => {
  return (
    <div className={styles.parent}>
      <div className={styles.wishlistParent} data-is-disabled={brandId === null ? '' : undefined}>
        <WishListButton isOnWishlist={isOnWishlist} onClick={onClickWishlist} />
      </div>
      <div className={styles.imageParent}>
        {imageSource && <img src={imageSource} alt={description} className={styles.image} />}
      </div>
      <div className={cx(linkStyle, styles.text, styles.name)} data-default-text>
        {name}
      </div>
      <div className={cx(linkStyle, styles.text, styles.hoverName)} data-hover-text>
        {name}
      </div>
    </div>
  )
}
