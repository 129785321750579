import { BrandsCard } from './BrandsCard';
import LocalizedLink from '@components/Localization/LocalizedLink';
import { parse } from '@config/theme';
import { useBrandPreferences } from '@lib/hooks/use-brand-preferences';
import { getKontentImage } from '@lib/kontent';
import { BrandListItem } from '@pages/_brands-page';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
type BrandsListBrandsRowProps = {
  items: BrandListItem[];
  isOpen?: boolean;
};
export const BrandsListBrandsRow: FC<BrandsListBrandsRowProps> = ({
  isOpen = true,
  items
}) => {
  const {
    toggleBrand,
    preferredBrands
  } = useBrandPreferences();
  const {
    asPath
  } = useRouter();
  const {
    t
  } = useTranslation('navigation');
  const shouldAlterTudorLink = asPath === '/house-of-gassan';
  return <div className={parent} data-open={isOpen ? '' : undefined}>
      {items.map((brand, index) => {
      const imageSource = brand.logo.value[0] ? getKontentImage(brand.logo.value[0].url, {
        width: 270
      }) : null;
      let href = `/${brand.url_slug.value}`;

      // Exception for Chopard
      if (brand.url_slug.value.toLowerCase().includes('chopard')) {
        href = '/chopard';
      }

      // Special exception for Tudor, link shouldn't be altered everywhere,
      // just here and in the Navigation
      if (href === '/tudor' && shouldAlterTudorLink) {
        href = t('about-tudor');
      }
      return <LocalizedLink href={href} key={index} passHref>
            <a>
              <BrandsCard description={brand.logo.name} imageSource={imageSource} name={brand.title.value} brandId={brand.brandId} isOnWishlist={preferredBrands.includes((brand.brandId as number))} onClickWishlist={() => toggleBrand((brand.brandId as number), brand.title.value)} />
            </a>
          </LocalizedLink>;
    })}
    </div>;
};
const parent = parse({
  display: 'grid'
}, "p1t7t99a");

require("./BrandsRow.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./BrandsRow.tsx");