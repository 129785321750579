import USPs from './USPs'
import * as styles from './styles'
import { Modal } from '@components/Modal'
import ContactForm from '@components/_contact/ContactForm'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Box, Button, Image } from '@gassan-ui'
import { LeadStore } from '@generated'
import { getKontentImage } from '@lib/kontent'
import * as Elements from '@lib/kontent/models/Elements'
import pinterestTracking from '@lib/pinterest-tracking'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { MarginBottomProps, MarginTopProps } from 'styled-system'

type FormType = 'default' | 'pre-owned'

type Props = MarginTopProps &
  MarginBottomProps & {
    title?: string
    text?: string
    customImage?: Elements.AssetsElement['value'][0]
    buttonText?: string
    subject?: string
    formType?: FormType
    variant?: 'product-page' | 'default'
    productId?: string
  }

const AppointmentUSPBlock: FC<Props> = ({
  text,
  title,
  buttonText,
  mt,
  mb = ['3.5rem', '4rem', '5rem', '7.5rem'],
  subject,
  productId,
  formType,
  customImage,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('other')
  const router = useRouter()
  const isProductPage = router.pathname.includes('/p/')

  return (
    <Box
      position="relative"
      pt={{
        _: '3rem',
        large: '3.5rem',
      }}
      pb={mb}
      mt={mt}
    >
      <div className={styles.coloredBackground} />
      <div className={styles.container}>
        <USPs />
        <div className={styles.coloredContainer}>
          <div className={styles.grid} id="make-an-appointment">
            <div className={styles.leftColumn}>
              <div className={styles.textParent}>
                <LinariaHeading variant="h2" as="h2" className={styles.heading}>
                  {title ? title : t('createAppointmentOrAskQuestion')}
                </LinariaHeading>
                <LinariaText className={styles.text}>
                  {text ? text : t('createAppointmentOrAskQuestionDescription')}
                </LinariaText>

                <Button variant="dark" data-open-contact-modal onClick={() => setIsOpen(true)}>
                  {buttonText ? buttonText : t('createAppointmentOrAskQuestionButton')}
                </Button>
                <Modal
                  open={isOpen}
                  onOpenChange={(open) => setIsOpen(open)}
                  title={t('contactUs')}
                >
                  <ContactForm
                    subject={subject}
                    leadStore={formType === 'pre-owned' ? LeadStore.PreOwned : LeadStore.Gassan}
                    isProductPage={isProductPage}
                    onSubmitSuccess={(values) => {
                      if (productId) {
                        pinterestTracking.lead({
                          lead_type:
                            values.type === 'APPOINTMENT'
                              ? 'product-appointment'
                              : 'product-question',
                          product_id: productId,
                        })
                      }
                      setIsOpen(false)
                    }}
                  />
                </Modal>
              </div>
            </div>
            {customImage ? (
              <Image
                className="h-full w-full object-cover"
                sources={[
                  getKontentImage(customImage.url, { width: 500 }),
                  getKontentImage(customImage.url, { width: 700 }),
                ]}
                placeholder={getKontentImage(customImage.url, { width: 30 })}
              />
            ) : (
              <img
                className="h-full w-full object-cover"
                src="/images/contact-form-featured-image.png"
                alt="Contact us"
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}

export default AppointmentUSPBlock
