// @ts-strict-ignore
// not using strict here because according to DAF, every property in the preferences query is optional
// in reality they're not optional, so by bypassing strict we're assuming that the properties exist
import { PreferencesQuery, UpdatePreferencesInput } from '@generated'

export function getSubmitBrandPreferencesInput(
  preferencesQuery: PreferencesQuery,
  preferredBrands: number[],
): UpdatePreferencesInput[] {
  if (
    !preferencesQuery ||
    !preferencesQuery.preferences ||
    preferencesQuery.preferences.length === 0
  )
    return []
  // Strip the preferences object from the query to only include the brand preferences
  // and then loop through the preferences to set the isSelected property
  let brandPreferences = preferencesQuery.preferences.find(
    (preference) => preference.preference_type_id === 1,
  )
  const otherPreferences = preferencesQuery.preferences.filter(
    (preference) => preference.preference_type_id !== 1,
  )

  if (!brandPreferences) {
    throw new Error('Brand preferences not found')
  }

  for (const preference of brandPreferences.preferences) {
    if (preferredBrands.includes(preference.preference_no)) {
      preference.isSelected = true
    } else {
      preference.isSelected = false
    }
  }

  // Strip out __typename from the preferences query response
  // graphql doesn't like it
  const stripped = [brandPreferences, ...otherPreferences].map((item) => {
    return {
      preference_type_id: item.preference_type_id,
      preference_type_description: item.preference_type_description,
      preferences: item.preferences
        ? item.preferences.map((preference) => ({
            preference_no: preference.preference_no,
            description: preference.description,
            profile: preference.profile,
            isSelected: preference.isSelected,
          }))
        : [],
    }
  })

  return stripped
}
