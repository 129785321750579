import { parse } from '@config/theme';
export const wrap = parse({
  display: 'flex',
  position: 'relative',
  width: '100%'
}, "wuww692");
export const parent = parse({
  position: 'absolute',
  height: '100%',
  width: '100%'
}, "pcq8e1k");
export const hoverContent = parse({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  textAlign: 'left',
  alignItems: 'flex-start'
}, "h1qyhrw4");
export const title = parse({}, "t61ukpk");
export const content = parse({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  py: {
    _: '2',
    small: '4',
    large: '6'
  }
}, "cy8gbrh");

require("./LinkImage.styles.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./LinkImage.styles.ts");