// @ts-strict-ignore
// not using strict here because according to DAF, every property in the preferences query is optional
// in reality they're not optional, so by bypassing strict we're assuming that the properties exist
import { PreferencesQuery } from '@generated'

export function getPreferredBrandsFromQuery(preferences: PreferencesQuery['preferences']) {
  if (!preferences || preferences.length === 0) return []

  const brandPreferences = preferences.find((preference) => preference?.preference_type_id === 1)

  if (!brandPreferences || !brandPreferences.preferences) return []

  return brandPreferences.preferences
    .map((preference) => preference.isSelected && preference.preference_no)
    .filter((item) => item)
}
