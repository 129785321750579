import { parse } from '../../config/theme';
export const coloredBackground = parse({
  bg: 'shade50',
  position: 'absolute',
  width: '100%'
}, "ccpkqty");
export const coloredContainer = parse({
  bg: 'pampas'
});
export const container = parse({
  position: 'relative'
}, "c43mpzs");
export const grid = parse({
  display: 'grid',
  width: '100%'
}, "gotv8d2");
export const leftColumn = "l1wkftg7";
export const textParent = parse({
  textAlign: {
    _: 'center',
    large: 'left'
  }
}, "tzesd0e");
export const heading = parse({
  width: '100%'
}, "hd4et3f");
export const text = parse({
  width: '100%'
}, "tfksum5");
export const image = parse({
  width: '100%',
  height: '100%'
}, "inaa316");

require("./styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");