import { parse } from '@config/theme';
export const parent = parse({
  display: 'block',
  position: 'relative',
  width: '100%'
}, "piixhp0");
export const wishlistParent = parse({
  position: 'absolute'
}, "w1g62sd");
export const imageParent = parse({
  width: '100%',
  position: 'relative'
}, "i6co6py");
export const image = parse({
  position: 'absolute'
}, "i7i6tn4");
export const text = "t1d54yuf";
export const name = parse({
  py: '5'
}, "n1gaguch");
export const hoverName = parse({
  position: 'absolute',
  py: '5'
}, "h1slhm62");

require("./BrandsCard.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./BrandsCard.styles.ts");