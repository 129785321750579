import { parse } from '@config/theme';
export const parent = parse({
  display: {
    _: 'grid',
    small: 'flex'
  },
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  mb: {
    _: '12',
    large: '14'
  }
}, "p5827un");
export const icon = parse({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: 'tabaccoBrown'
}, "i1yggt1q");
export const mobileIcon = parse({
  display: {
    _: 'block',
    small: 'none'
  }
});
export const desktopIcon = parse({
  display: {
    _: 'none',
    small: 'block'
  }
});

require("./USPs.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./USPs.styles.ts");